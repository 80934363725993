import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import Stack from '@mui/material/Stack';

import {BrowserRouter, Routes, Navigate, Link, useLocation} from 'react-router-dom'
import { SigloAn } from '../pages/SigloAn';
import { Pertigas } from '../pages/Pertigas';
import MenuListItem1 from './MenuListItem1';
import MenuListItem2 from './MenuListItem2';

function ConfigLogos(props) {
  if (props.pathname=='/' || props.pathname=='/pertigas' || props.pathname=='/fotos'){
    return <>
      <a href="https://www.facebook.com/Seguimiento-de-Nieve-Siglo-An-110665023757515"  target="_blank" style={{ height: "36px" }}> 
        <img src={require('../../assets/logos/facebook.png')} style={{ height: "36px", width: '100%' }} />
      </a>
      <a href='https://twitter.com/Nieve_SIGLO_AN' target="_blank" style={{ height: "36px" }}> 
          <img src={require('../../assets/logos/twitter.png')} style={{ height: "36px", width: '100%' }} />
      </a>
      </>
  }else{
    return <></>
  }
}
export default function MenuListComposition() {
  const location = useLocation()
  
  return (
    <Stack direction="row" spacing={2}>
      <MenuListItem1/>
      <MenuListItem2/>
      <ConfigLogos pathname={location.pathname} />
    </Stack>
    
  );
}