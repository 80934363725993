import React, { useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet";
import { Icon } from "leaflet";
import { defaultMarker } from "./defaultMarker";
import  {popupContent, popupHead, popupText, okText, imagen}  from "./popupStyles";
//import { leaflet-container } from './styles'
//import DBpertigas  from '../../../assets/pertigas.json'
import Iconpertiga from '../Iconpertiga/Iconpretiga'
import ic_pertiga from '../../../assets/pertiga.svg';
import { useAppContext } from '../../providers/AppProvider'
import analytics from '../../../scripts/components/google/analytics'


const icon= new Icon({
  iconUrl:ic_pertiga,
  iconSize:[60,60]
})


const DEFAULT_IMAGE = 'https://s1.wklcdn.com/image_256/7702849/60633139/40412521Master.jpg'
const BASE_URL='https://seguimientodenieve.es/fotosConfig'

export const Mapa = ({DBpertigas}) => {
   //const DBpertigas=pertigas
  //  console.log("Mapa: ",DBpertigas)
  let origen={lat:37,lon:-3.197537}
  
  //const [activePertiga,setActivePertiga] = useState(null);
  
  var activePertiga
  const onActivePertiga=(p)=>{
    let payload={category:"PertigaMark", action:"onClickMark", label:p.zona}
    console.log('PertigaMark: ',payload)
      analytics.sendEvent(payload)
  }

  return (<MapContainer center={[37.0835343,-3.21389] }zoom={11}>
    <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {
        DBpertigas.map(pertiga=> <Marker
          key={pertiga.id}
          position={[pertiga.latitude,pertiga.longitude]}
          icon={icon}
          /* onClick= {
            onActivePertiga(pertiga)
          } */
          eventHandlers={{
            click: (e) => {
              //console.log('marker clicked', pertiga)
              onActivePertiga(pertiga)
            },
            mouseover: (e)=>{
              e.target.openPopup()
            }
          }}
        >
          <Popup className="request-popup">
          <div style={popupContent}>
            <img src={BASE_URL+'/'+pertiga.fotosConfig[0]} style={imagen} alt={pertiga.zona}/> 
            <div className="m-2" style={popupHead}>
            {pertiga.zona}
            
            <span style={popupText}>
            Codigo: {pertiga.id}<br></br>
            Altitud: {pertiga.altitud}
            </span>
            
            </div>
          </div>
           </Popup>
        </Marker>
          
        )
      }

      
    </MapContainer>)
  
}