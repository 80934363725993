import styled from 'styled-components'

const popupContent = {
    textAlign: "center",
    height: "100px",
    marginTop: "5px",
    display: "inline-flex"
    
  };
  const popupHead = {
    fontWeight: "bold",
    fontSize: "13px",
    display: "grid",
    paddingTop: "1rem",
    alignContent: "center",
    marginLeft: "1rem"
  };
  
  const popupText = {
    fontWeight: "normal",
    fontSize: "11px",
    marginBottom: "20px",
    textAlign: "left"
  };
  
  const okText = {
    fontSize: "15px"
  };
  
  const imagen ={
    height: "90px",
    objectFit: "contain"
  }
  export { popupContent, popupHead, popupText, okText , imagen};