import React from 'react'
import { Pertiga } from './Pertiga'
import PertigaCard from './PertigaCard'
//import DBpertigas  from '../../../assets/pertigas.json'
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: '0px',
  alignItems:'center'
}));

export const ListOfPertigas = ({DBpertigas}) => {
  console.log(DBpertigas)
  return (
    DBpertigas.map(pertiga => 
    <Grid item xs={12} sm={4} key={pertiga.id}>
                                <Item><PertigaCard {...pertiga}/></Item>
                            </Grid>  

        )
      
    
  )
}