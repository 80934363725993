import React from "react";
import { Mapa } from "../components/Map";
import { ListOfPertigas } from "../components/pertigas/ListOfPertigas"
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { height } from "@mui/system";
import PertigaCard from "../components/pertigas/PertigaCard";
import DBpertigas  from '../../assets/pertigas.json'
import { Stack } from "@mui/material";
import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '0px',
    alignItems:'center'
  }));

export const Pertigas = () =>{
    
    return(        
            <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                        <Item>
                            <video loop autoPlay name="media" playsinline="true" id= "video" style={{width:"100%", height:"200px",top:'0',left:'0',objectFit:'cover',zIndex:'-1'}}>
                            <source src="http://seguimientodenieve.es/webwp/wp-content/uploads/seguimientoNieve01_3.mp4" type="video/mp4" />     
                            </video>
                            {/*  */}
                        </Item>
                    
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Item >
                            <Mapa  DBpertigas= {DBpertigas} />
                        </Item>
                    </Grid>
                    <Grid item xs={12} >
                            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}  columns={{ xs: 4, sm: 8, md: 12 }} >
                            <ListOfPertigas DBpertigas= {DBpertigas} />
                            {/* <Grid item xs={4}>
                                <Item><PertigaCard {...DBpertigas[1]}/></Item>
                            </Grid>
                            <Grid item xs={4}>
                                <Item><PertigaCard {...DBpertigas[2]}/></Item>
                            </Grid>
                            <Grid item xs={6}>
                                <Item>4</Item>
                            </Grid> */}
                            </Grid>
                    </Grid>

                    {/* <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Item >
                            <PertigaCard {...DBpertigas[0]}/>
                        </Item>
                        <Item >
                            <PertigaCard {...DBpertigas[1]}/>
                        </Item>
                    </Grid>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                            <ListOfPertigas DBpertigas= {DBpertigas} />
                    </Stack>
                        
                    </Grid> */}
                    
                </Grid>

                <Grid item xs={12} >
                    <Item > 
                    <a href='https://es.wikiloc.com/rutas-senderismo/ubicacion-pertigas-proyecto-seguimiento-de-nieve-siglo-an-61658512' target='_blank'>
                    <img src={require('../../assets/landpage/wikiloc.jpeg') } style={{width:'50%',height:'50%',objectFit:'cover',marginTop:"15px"/* ,objectPosition: '0px -250px' */}}/>
                    </a>
                    </Item>
                </Grid>

                <Grid item xs={12}>
                <Item > 
                <Typography variant='p'  style={{padding:'50px 20px'}}>
                <p>SIGLO-AN (RTI2018-101397-B-I00) financiado por el Programa Estatal de I+D+I orientada a los Retos de la Sociedad
                </p><p>
                    Instituto Geológico y Minero de España (IGME-CSIC)
                    Departamento de Aguas y Cambio Global
                    Urbanización Alcázar del Genil nº 4. Edificio Zulema. Bajo. 18006 Granada
                    </p><p>
                    "info@seguimientodenieve.es" 
                    "d.pulido@igme.es"</p>
                    </Typography>
                </Item>
                </Grid>
            </Box> 
          
        
        
       /*  <div>
            <div  id="mapid"> 
                <Mapa/>
            </div>
            <div className="flex ">
                <ListOfPertigas /> 
            </div>
        </div> */
    )
}