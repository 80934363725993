import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const FotosRankin = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Item>
              <Typography variant='p'/*style={ {padding:'10px 20px'} }*/>
                 <h3> 
                     El ganador del ranking de la temporada 2020-2021, <strong style={{color:'#444'}}>Jaime Palacín</strong>, recibió el premio de 2 noches en el Refugio de Poqueira, patrocinado por la <a href='https://fedamon.es/jaime-palacin-primer-premiado-a-la-iniciativa-ciudadana-mas-activa-en-el-proyecto-siglo-an/?preview=true&_thumbnail_id=11425' target={'_blank'}>FAM</a>.
                 </h3>
              </Typography>
          <img src={require('../../assets/premio2021/ganador_2020-21.jpg')} style={{margin:'auto',width:"100%"}}></img>
          
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item style={{boxShadow: 'none'}}>
          
            <h3 style={{  textAlign: 'left', marginLeft: '20px'}}>
                  Estas son algunas fotos de ejemplo enviadas por el ganador. 
              </h3>
          </Item>
        </Grid>
        <Grid item xs={4}>          
          <Item>
          <img src={require('../../assets/premio2021/2021-01-11_Arroyo_San_Juan_Jaime_Palacin.jpg')} style={{margin:'auto',width:'100%'}}></img>
          <p style={{  textAlign: 'left', marginLeft: '20px'}}>
          Arroyo San Juan. 
          </p>
          </Item>
        </Grid>
        <Grid item xs={4}>
        <Item>
          <img src={require('../../assets/premio2021/2021-03-10_Laguna_Yeguas_(Borreguiles_del_Dilar)_Jaime_Palacin.jpg')} style={{margin:'auto',width:'100%'}}></img>
          <p style={{  textAlign: 'left', marginLeft: '20px'}}>
          Laguna Yeguas.
          </p>
          </Item>
        </Grid>
        <Grid item xs={4}>
        <Item>
          <img src={require('../../assets/premio2021/2021-03-26_Laguna_Rio_Seco_Jaime_Palacin.jpg')} style={{margin:'auto',width:'100%'}}></img>
          <p style={{  textAlign: 'left', marginLeft: '20px'}}>
          Laguna Rio Seco. 
          </p>
          </Item>
        </Grid>
        

        <Grid item xs={12}>
          <Item>
          <Typography variant='p'style={{textAlign: 'left',padding:'10px 20px'}}>
            <h3>Estas imágenes nos sirven para llevar a cabo estudios como:</h3>
            <p>
            Collados-Lara, Antonio-Juan & Pulido-Velazquez, David & Pardo-Iguzquiza, Eulogio & Alonso-González, Esteban. (2020). Estimation of the spatiotemporal dynamic of snow water equivalent at mountain range scale under data scarcity. Science of The Total Environment. 741. <a href='https://doi.org/10.1016/j.scitotenv.2020.140485' target={'_blank'}>link</a>
            <p></p>
            Jimeno-Sáez, P.; Pulido-Velazquez, D.; Collados-Lara AJ, Pardo-Igúzquiza, E.; Senent-Aparicio, J. Baena-Ruiz, L.; 2020. A Preliminary Assessment of the “Undercatching” and the Precipitation Pattern in an Alpine Basin. Water 2020, 12, 1061; <a href='https://doi.org/10.3390/w12041061' target={'_blank'}>link</a>
            <p></p>
            Collados-Lara AJ, Pardo-Igúzquiza E, Pulido-Velazquez D, 2020. Optimal design of snow stake networks to estimate snow depth in an alpine mountain. Hydrological Processes. <a href='https://doi.org/10.1002/hyp.13574' target={'_blank'}>link</a>
            <p></p>
            Collados-Lara A-J, Pardo-Igúzquiza E, Pulido-Velazquez D, 2017. Spatiotemporal estimation of snow depth using point data from snow stakes, digital terrain models, and satellite data. Hydrological Processes. 2017; 31:1966–1982.<a href='https://doi.org/10.1002/hyp.11165' target={'_blank'}>link</a>
            </p>
          </Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
        <Item > 
          <Typography variant='p'  style={{padding:'50px 20px'}}>
          <p>SIGLO-AN (RTI2018-101397-B-I00) financiado por el Programa Estatal de I+D+I orientada a los Retos de la Sociedad
          </p><p>
            Instituto Geológico y Minero de España (IGME-CSIC)
            Departamento de Aguas y Cambio Global
            Urbanización Alcázar del Genil nº 4. Edificio Zulema. Bajo. 18006 Granada
            </p><p>
            "info@seguimientodenieve.es" 
            "d.pulido@igme.es"</p>
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
