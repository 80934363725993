import ReactGA from 'react-ga4'


const TRACKING_ID_test="G-4Y34CYH3VP"
const TRACKING_ID_OFICIAL="G-58EMY96Q0D" 
const TRACKING_ID= TRACKING_ID_OFICIAL

function init(){

  ReactGA.initialize(TRACKING_ID,{debug:true});

}

function sendEvent(payload){
  ReactGA.event(payload)
}

function sendPageview(path){
  console.log('sendPageview: ', path)
    //ReactGA.set({hitType:"pageview",page:path})
    ReactGA.send({hitType:"pageview",label:path})
}

export default{
  init,
  sendEvent,
  sendPageview,
}