import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as geolib from 'geolib'
import analytics from '../../../scripts/components/google/analytics'

const DEFAULT_IMAGE = 'https://s1.wklcdn.com/image_256/7702849/60633139/40412521Master.jpg'
// const BASE_URL='https://seguimientodenieve.es/wp-content/uploads/fotosConfig'
const BASE_URL='https://seguimientodenieve.es/fotosConfig'


export default function PertigaCard( {id, zona,altitud, latitude,longitude, linkWikilocImg,linkWikilocRoute,fotosConfig,otro}) {
  /* console.log("CARD",id,": ", geolib.decimalToSexagesimal(latitude)); */
  
  const [distancia, setDistancia]=React.useState(0);
  const [direccion, setDireccion]=React.useState("")

  const distance=(latitude,longitude)=>{
      navigator.geolocation.getCurrentPosition(
        (position) => {

          setDistancia(  geolib.getDistance({latitude:position.coords.latitude,longitude:position.coords.longitude}, {
              latitude: latitude,
              longitude: longitude,
          })); 
          setDireccion(geolib.getCompassDirection({latitude:position.coords.latitude,longitude:position.coords.longitude}, {
            latitude: latitude,
            longitude: longitude,
          }));
            
        },
        (err) => {
          setDistancia('La posición no ha podido ser determinada. Para calcular la distancia hasta las pertigas se necesita permiso de localización'+' -- '+ err.message);
        }
    );
  }
  
  function onClickHandle(){

    let payload={category:"PertigaCard", action:"onClickCard", label:zona}
    console.log('PertigaCard: ',payload)
      analytics.sendEvent(payload)
  }
  
  distance(latitude,longitude)
  
  
  const card = (
    <Card sx={{  display:'flex'}}>
      <CardMedia
        component="img"
        //height="200"
        sx={{ width: 150 , height: 270}}
        image= {BASE_URL+'/'+fotosConfig[0]}
        alt={zona}
        onClick={onClickHandle}
      />
      <CardContent md={{display:'flex',flexDirection:'column'}}>
        <Typography gutterBottom variant="h5" component="div">
          {zona} 
        </Typography>
        <Typography gutterBottom variant="subtitle1" component="div">
          {otro} 
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'left' }}>
          
            <strong>Altidud:</strong> {altitud} <br />
            <strong>Latitud:</strong> {geolib.decimalToSexagesimal(latitude)} <br />
            <strong>Longitud:</strong> {geolib.decimalToSexagesimal(longitude)}<br />
            <strong>Distancia:</strong> {distancia.toLocaleString()}m {direccion}
        </Typography>
        {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
      </CardContent>
      
    </Card>
  );

  return (
    <Card variant="outlined">{card}</Card>
  )
}
