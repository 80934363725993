export const LOGOS={
    'sigloan':require('../../assets/logos/logoSigloAn.png'),
    'aei':require('../../assets/logos/AEI.png'),
    'min':require('../../assets/logos/A4_Min_IGMEvertic_color.jpg'),
    'parque':require('../../assets/logos/SN_parque.jpg'),
    'fam':require('../../assets/logos/FAM.png'),
    'erhin':require('../../assets/logos/erhin.jpg'),
    'dipgra':require('../../assets/logos/dipgra.png'),
}

