
import './App.css';
import {Cabecera}   from './scripts/cabecera/Cabecera';
import MenuListComposition from './scripts/menu/MenuListComposition';
import {BrowserRouter, Routes, Route, Link} from 'react-router-dom'
import { StyledEngineProvider } from '@mui/material/styles';
import { MenuPrincipal } from './scripts/menu/MenuPrincipal';
import ListRouter from './scripts/menu/MenuRouter';
import { SigloAn } from './scripts/pages/SigloAn';
import { Pertigas } from './scripts/pages/Pertigas';
import Home from './scripts/pages/Home';
import { FotosRankin } from './scripts/pages/FotosRanking';
import { Jornadas } from './scripts/pages/Jornadas';
import { Resultados } from './scripts/pages/Resultados';
import { AppProvider } from './scripts/providers/AppProvider';
import  useGoogleAnalytics  from './scripts/components/google/googleAnalytics'
import CookieConsent from 'react-cookie-consent'

function Rutas() {
  useGoogleAnalytics()
  return(
    <Routes>
      <Route path="/proyecto" element={<SigloAn />} />
      <Route path="/pertigas" element={<Pertigas />} />
      <Route path="/fotos" element={<FotosRankin />} />
      <Route path="/jornadas" element={<Jornadas/>}/>
      <Route path="/resultados" element={<Resultados />} />
      <Route path="/" element={<Home />} />
    </Routes>
  )
}

function App() {


  return (
     <AppProvider>
      <div className="App">
        <div className="App-content">  
          <StyledEngineProvider injectFirst>
            <BrowserRouter>
              <Cabecera/>
              <nav>
                <MenuListComposition/>
              </nav>           
              
              <Rutas/>

            </BrowserRouter>
          </StyledEngineProvider>
        </div>
      </div>
      <CookieConsent debug={true} acceptOnScroll={true} acceptOnOverlayClick={true} buttonText='Entendido'>Esta web utiliza cookies para mejorar la experiencia.   </CookieConsent>
     </AppProvider>
     
  );
}

export default App;
