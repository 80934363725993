import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const SigloAn = () => {
  return (
<Box sx={{ width: '100%' }}>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Item>
              <Typography variant='p'style={{padding:'10px 20px'}}>
                 <h2> 
                 Seguimiento y evaluación de impactos del cambio global en sistemas de recursos hídricos dependientes del almacenamiento natural en acuíferos y/o en forma de nieve. SIGLO-AN
                 </h2>
              </Typography>
          </Item>
          </Grid>
          <Grid item xs={12}>
          <Item>
              <Typography variant='p'style={{textAlign: 'left',padding:'10px 20px'}}>
                
                <h2>RESUMEN DEL PROYECTO</h2>
                
                <p>El objetivo del proyecto es contribuir a mejorar el conocimiento sobre los impactos del cambio climático (CC) y/o el cambio global (CG). 
                </p><p>
                La propuesta está estructurada en 3 objetivos/tareas generales: </p>
                <p>0) Contribuir al seguimiento del CC. Se diseñará una propuesta de iniciativa ciudadana para monitorizar espesores de nieve en regiones alpinas con pocos datos, la cual se aplicará en Sierra Nevada. Además, se propondrán/adaptarán y aplicarán metodologías y herramientas para estudiar impactos del CC sobre el estado y la vulnerabilidad de sistemas dependientes del almacenamiento natural en forma de nieve 
                (1) y/o en acuíferos (2). </p><p>
                Se analizarán casos de estudio con diferentes escalas espaciales, y características físicas y/o de gestión. En la cordillera de Sierra Nevada 
                (11) analizaremos los impactos del CC en la dinámica de la nieve, evaluando la Superficie Cubierta de Nieve, el espesor y el agua equivalente.
                </p><p> A escala de cuenca, en la cuenca del Alto Genil (1II), se evaluarán las consecuencias hidrológicas de la propagación de impactos en la dinámica de la nieve. Se abordarán problemas como el “undercatching” (errores sistemáticos en la medición de nieve que dependen en gran medida del viento), importantes para una adecuada evaluación de recursos. 
                </p><p> Se analizan las dificultades adicionales que supone la modelación hidrológica incluyendo procesos y variables nivales mediante variantes de aproximaciones clásicas y técnicas novedosas (ej. autómatas celulares). 
                </p><p> Se estudiará la sensibilidad de incorporar información adicional sobre la capa de nieve en distintas fases de la modelación hidrológica (calibración y validación). 
                </p><p> Por otro lado, se propone analizar los impactos del CC y/o del CG sobre el estado y la vulnerabilidad (al bombeo y contaminación) de acuíferos a escala peninsular 
                (21). En la cuenca del Segura (2II), donde las aguas subterráneas juegan un papel fundamental, se estudiarán temas relevantes como la sequía y la subsidencia. 
                </p><p> Para cada caso de estudio/problema (11, 1II, 21, 2II) se proponen novedosos objetivos/tareas concretas para contribuir a la generación de conocimiento que sirva de apoyo para la toma de decisiones (carta de interés de diferentes sectores afectados y grupo de expertos internacionales). 
                </p><p> Para la evaluación de impactos se seguirán los siguientes pasos: </p>
                <ul>
                <li>S1) Definición de escenarios climáticos históricos y futuros a corto plazo (según los escenarios de emisión publicados por el IPCC) generando series/campos de variables climáticas adaptadas a los sistemas y problemas abordados; </li>
                <li>S2) Definición de posibles medidas de adaptación; </li>
                <li>S3) Desarrollar modelos numéricos que integren información histórica (incluida información de satélite). </li>
                </ul>
                <p>Estos modelos se usarán para propagar impactos CC y/o CG bajo diferentes estrategias de adaptación. 
                </p><p>Es una propuesta multidisciplinar (climatología, hidrología, agronomía, informática, etc.) que cubre un amplio espectro temático, favoreciendo una potencial internacionalización, generando resultados que constituyen unos antecedentes ideales para concurrir a convocatorias de diversa índole. 
                </p><p>La viabilidad de la propuesta está respaldada por una reconocida trayectoria en la línea CC, con proyectos financiados que abarcan diferentes temas y casos de estudio. 
                </p><p>Se formó un equipo multidisciplinar que incorpora miembros que permiten abordar nuevos problemas (por ej., Subsidencia) y se implicó a investigadores extranjeros para favorecer la potencial internacionalización de los resultados.

                </p>
              </Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
              <Typography variant='p'style={{textAlign: 'left',padding:'10px 20px'}}>
                <h2>MIEMBROS DEL EQUIPO DEL PROYECTO</h2>
                <p>
                    Equipo de investigación:</p>
                <ul>
                    <li> Dr. David Pulido Velázquez, Senior Researcher (Científico Titular), IGME (Project PI) </li>
                    <li> Dr. Jose Luis García Arostegui, Senior Researcher (Científico Titular), IGME </li>
                    <li> Dr. Ignacio Morel Evangelista. University Professor, UJI </li>
                    <li> Dr. Francisco Javier Alcalá García, Senior Researcher, UCAM </li>
                    <li> Juan de Dios Gómez Gómez, Specialized Technician of IGME </li>
                    <li> Dra. África de la Hera Portillo, Senior Researcher (Científico Titular), IGME </li>
                    <li> Dra. Rosa María Mateos Ruiz, Senior Researcher (Científico Titular), IGME </li>
                    <li> Dr. Fernando Delgado Ramos, Associate Professor, UGR </li>
                    <li> Crisanto Martín Montañez, Specialized Technician of the IGME </li>
                </ul>
                
              </Typography>
              <Typography variant='p'style={{textAlign: 'left',padding:'10px 20px'}}>
                <p>
                    Equipo de trabajo:</p>
              <ul>
              
                    <li> Dr. Klaus Hinsby, Scientist, Geological Survey of Denmark and Greenland (GEUS) </li>
                    <li> Dr. Thomas Skaugen, Research professor, Norwegian WR and Energy Advisorate </li>
                    <li> Dr. Anker Lajer Højberg, Scientist, Geological Survey of Denmark and Greenland (GEUS) </li>
                    <li> Dra. Aynur Sensoy Sorman, Associate Professor, Anadolu University (Turkey) </li>
                    <li> Dr. David Christian Finger, Assistant Professor, Reykjavík University (Iceland) </li>
                    <li> Dr. Manuel Pegalajar Cuéllar, University Professor, UGR </li>
                    <li> Dr. Eulogio Pardo Igúzquiza, Scientific Researcher, IGME </li>
                    <li> Dr. Arianna Renau Pruñonosa, Hired posdoct researcher, UJI </li>
                    <li> Dr. Antonio Juan Collados Lara, Predoctoral Contract FPI MINECO, IGME </li>
                    <li> Dra. Leticia Baena Ruiz, Contract Practices, IGME </li>
                </ul>
                
              </Typography>
              
          </Item>
        </Grid>
    </Grid>
    <Grid item xs={12} >
          <Item > 
          <Typography variant='p'  style={{padding:'50px 20px'}}>
          <p>SIGLO-AN (RTI2018-101397-B-I00) financiado por el Programa Estatal de I+D+I orientada a los Retos de la Sociedad
          </p><p>
            Instituto Geológico y Minero de España (IGME-CSIC)
            Departamento de Aguas y Cambio Global
            Urbanización Alcázar del Genil nº 4. Edificio Zulema. Bajo. 18006 Granada
            </p><p>
            "info@seguimientodenieve.es" 
            "d.pulido@igme.es"</p>
            </Typography>
          </Item>
        </Grid>
  </Box>
  )
}
