import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';


import Stack from '@mui/material/Stack';

import {BrowserRouter, Routes, Route, Link, useLocation, useNavigate} from 'react-router-dom'
import { SigloAn } from '../pages/SigloAn';
import { Pertigas } from '../pages/Pertigas';

export default function MenuListItem2() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  
  const location= useLocation();
  const navigate = useNavigate()


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {

     //console.log("handleClose",event ,event.target.id,location)
    /* if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    } */
    /*alert("handleClose "+ event.type ,event.target.id,location)
    if(event.type!="mouseleave"){ 
      switch(event.target.id){
        case 'proyecto':
          navigate('/proyecto')
        break;
        case 'jornadas':
          navigate('/jornadas')
        break;
        case 'resultados':
          navigate('/resultados')
        break;
        
      }
      setOpen(false);
     }else{
      // console.log("handleClose mouseleave",event ,event.relatedTarget.id,location)
      if(event.relatedTarget.id!="iniciativa" && event.relatedTarget.id!="composition2-menu"){
        setOpen(false);
      }

    } */
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);



  function ListItemLink(props) {
    const { icon, primary, to } = props;
  
    const renderLink = React.useMemo(
      () =>
        React.forwardRef(function Link(itemProps, ref) {
          return <Link to={to} ref={ref} {...itemProps} role={undefined} />;
        }),
      [to],
    );
  
    return (
      <li>
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      </li>
    );
  }
  
  return (
    

      <div>
        <Button
          ref={anchorRef}
          id="composition2-button"
          aria-controls={open ? 'composition2-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          onMouseOver={handleOpen}
          /* onMouseLeave={handleClose} */
          style={{color:"white"}}
        >
          Proyecto
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          onMouseLeave={handleClose}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition2-menu"
                    aria-labelledby="composition2-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {/* <MenuItem onClick={handleClose}>Proyecto</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem>*/}
                    <MenuItem onClick={handleClose} id='proyecto' component={Link} to="/proyecto" >Resumen y Equipo</MenuItem>
                    <MenuItem onClick={handleClose} id='jornadas'component={Link} to="/jornadas" >Jornadas</MenuItem>
                    <MenuItem onClick={handleClose} id='resultados' style={{cursor:"pointer",zIndex:"100"}} component={Link} to="/resultados" >Resultados</MenuItem>
                     {/* <ListItemLink to="/pertigas" primary="Pertigas"  />  */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      
   
    
  );
}