import React from 'react';
import { Link, useLocation, useParams,  } from 'react-router-dom';
import { LOGOS } from '../enums/Logos';

const altura='60px'; 

function ConfigLogos(props) {
  // console.log('Cabecera configLogos: ',props.pathname, LOGOS);
  
  //return LOGOS.map( logo => <img src={logo} height='70px' style={{marginLeft:"15px"}}></img>)
  if (props.pathname=='/' || props.pathname=='/pertigas' || props.pathname=='/fotos'){
    return <div>
      <img src={require('../../assets/logos/AEI.png')}  style={{ height: altura,marginLeft:"15px" }}></img>
      <img src={require('../../assets/logos/A4_Min_IGMEvertic_color.jpg')}  style={{ height: altura,marginLeft:"15px" }}></img>
      <img src={require('../../assets/logos/SN_parque.jpg')}  style={{ height: altura,marginLeft:"15px" }}></img>
      <img src={require('../../assets/logos/FAM.png')}  style={{ height: altura,marginLeft:"15px" }}></img>
      <img src={require('../../assets/logos/erhin.jpg')}  style={{ height: altura,marginLeft:"15px" }}></img>
      <img src={require('../../assets/logos/dipgra.png')}  style={{ height: altura,marginLeft:"15px" }}></img>
      </div>
  }else{
    return <div>
      <img src={require('../../assets/logos/AEI.png')}  style={{ height: altura,marginLeft:"15px" }}></img>
      <img src={require('../../assets/logos/A4_Min_IGMEvertic_color.jpg')}  style={{ height: altura,marginLeft:"15px" }}></img>
      
      </div>
  }
  
}

export const Cabecera = () => {
    const location = useLocation()
    /* console.log('Cabecera: ',location) */
    
  
    
    return (
    
    
    
    <div className="logos" style={{ display: "Flex" , justifyContent:"space-between", flexWrap: 'wrap'}}>    
      {
          // LOGOS.map( logo => <img src={logo} height='70px' style={{marginLeft:"15px"}}></img>)
          <>
            <Link to='/'> 
              <img src={require('../../assets/logos/logoSigloAn.png')}  style={{ height: altura, width: '100%' }}/>
            </Link>
            <ConfigLogos pathname={location.pathname} /></>
          

          
      }
     
    </div>
  )
}
