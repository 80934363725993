import React, { useContext, useReducer }from 'react'


const AppContext = React.createContext();





const useAppContext= () =>{
    return useContext(AppContext)
}

const initialState={
    pertigaActual:{},
    page:''
}

const reducer = (state, action) =>{

    return state;
}

const AppProvider = ({children}) => {

    const [state, dispatch]= useReducer(reducer,initialState)


  return (
    <AppContext.Provider value={{pertigaActual: state.pertigaActual, page:state.page, dispatch}}>
        {children}
    </AppContext.Provider>
  )
}

export {
    AppProvider,
    useAppContext
} 