import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const Resultados = () => {
  return (
    <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
            <Item>
                <Typography variant='p'style={{textAlign: 'left',padding:'10px 20px'}}>
                    <h2> 
                    Pubicaciones SCI
                    </h2>
                    <ul>
                        <li>Collados-Lara, A.-J., Pulido-Velazquez, D., Gómez-Gómez, J.-D., and Pardo-Igúzquiza, 2022, E.: An approach to identify the best climate models for the assessment of climate change impacts on meteorological and hydrological droughts, Nat. Hazards Earth Syst. Sci. Discuss. [preprint], <a href="https://doi.org/10.5194/nhess-2021-121" target="_blank">https://doi.org/10.5194/nhess-2021-121.</a> </li>
                        <li>G&oacute;mez, Juan de Dios &amp; Pulido-velazquez, David &amp; Collados-Lara, Antonio-Juan &amp; Chac&oacute;n, Francisca. (2022). The impact of climate change scenarios on droughts and their propagation in an arid Mediterranean basin. A useful approach for planning adaptation strategies. Science of The Total Environment. 153128. <a href="https://doi.org/10.1016/j.scitotenv.2022.153128" target="_blank">https://doi.org/10.1016/j.scitotenv.2022.153128</a></li>
                        <li>Martinsen, Grith &amp; Bessiere, Helene &amp; Caballero, Yvan &amp; Koch, Julian &amp; Collados-Lara, Antonio-Juan &amp; Mansour, Majdi &amp; Sallasmaa, Olli &amp; Vel&aacute;zquez, David &amp; Hunter Williams, N. &amp; Zaadnoordijk, Willem &amp; Stisen, Simon. (2022). Developing a pan-European high-resolution groundwater recharge map &ndash; Combining satellite data and national survey data using machine learning. Science of The Total Environment. 822. 153464. <a href="https://doi.org/10.1016/j.scitotenv.2022.153464" target="_blank">https://doi.org/10.1016/j.scitotenv.2022.153464</a></li>
                        <li>D. Pulido-Velazquez, L. Baena-Ruiz, J. Fernandes, G. Arn&oacute;, K. Hinsby, D.D. Voutchkova, B. Hansen, I. Retike, J. Bik&scaron;e, A.J. Collados-Lara, V. Camps, I. Morel, J. Grima-Olmedo, J.A. Luque-Espinar, Assessment of chloride natural background levels by applying statistical approaches. Analyses of European coastal aquifers in different environments, Marine Pollution Bulletin, Volume 174, 2022, 113303, ISSN 0025-326X, <a href="https://doi.org/10.1016/j.marpolbul.2021.113303" target="_blank">https://doi.org/10.1016/j.marpolbul.2021.113303</a></li>
                        <li>Pulido-Velazquez, D.; Collados-Lara, A.J.&nbsp;; Segura-M&eacute;ndez, F.J.&nbsp;; P&eacute;rez-Sanchez, J.&nbsp;; Senent-Aparicio, J., 2021. Impacts of future potential climate change scenarios on the stream-flow in the Spanish Basins monitorized under near-natural conditions. J. Hydrology Regional studies. 38 (2021) 100937 <a href="https://doi.org/10.1016/j.ejrh.2021.100937" target="_blank">https://doi.org/10.1016/j.ejrh.2021.100937</a></li>
                        <li>Collados-Lara, A.-J.; Pardo- Ig&uacute;zquiza, E.; Pulido-Velazquez, D.; Baena-Ruiz, L. Estimation of the Monthly Dynamics of SurfaceWater in Wetlands from Satellite and Secondary Hydro-Climatological Data. Remote Sens. 2021, 13, 2380. <a href="https://doi.org/10.3390/rs13122380" target="_blank">https://doi.org/10.3390/rs13122380</a></li>
                        <li>Collados-Lara; AJ, Pulido-Velazquez; D. Pardo-Ig&uacute;zquiza, E., 2021. Assessing the impact of climate change &minus;and its uncertainty&minus; on snow cover areas by using cellular automata models and stochastic weather generators. Science of the Total Environment. <a href="https://doi.org/10.1016/j.scitotenv.2021.147776" target="_blank">https://doi.org/10.1016/j.scitotenv.2021.147776</a></li>
                        <li>Baena-Ruiz, L., Pulido-Velazquez, D. GIS-SWIAS: Tool to Summarize Seawater Intrusion Status and Vulnerability at Aquifer Scale," Scientific Programming, vol. 2021, Article ID 8818634, 15 pages, 2021. <a href="https://doi.org/10.1155/2021/8818634" target="_blank">https://doi.org/10.1155/2021/8818634</a></li>
                        <li>Baena-Ruiz, Leticia &amp; Pulido-velazquez, David &amp; Collados-Lara, Antonio-Juan &amp; G&oacute;mez, Juan de Dios. (2021). A Preliminary Lumped Assessment of Pollution Risk at Aquifer Scale by Using the Mean Residence Time. Analyses of Potential Climate Change Impacts. Water. 13. 943. <a href="https://doi.org/10.3390/w13070943" target="_blank">https://doi.org/10.3390/w13070943</a></li>
                        <li>Collados-Lara, Antonio-Juan &amp; Fassnacht, S. &amp; Pardo-Iguzquiza, Eulogio &amp; Pulido-velazquez, David. (2020). Assessment of High Resolution Air Temperature Fields at Rocky Mountain National Park by Combining Scarce Point Measurements with Elevation and Remote Sensing Data. Remote Sensing. 13. <a href="https://doi.org/10.3390/rs13010113" target="_blank">https://doi.org/10.3390/rs13010113</a></li>
                        <li>Alcal&aacute;, Francisco &amp; Pulido-velazquez, David &amp; Ribeiro, Lu&iacute;s. (2020). Impacts of Climate on Renewable Groundwater Resources and/or Stream-Aquifer Interactions. Water. 12. 3480. <a href="https://doi.org/10.3390/w12123480" target="_blank">https://doi.org/10.3390/w12123480</a></li>
                        <li>Calvache, M. &amp; Duque, Carlos &amp; Pulido-velazquez, David. (2020). Summary Editorial: Impacts of global change on groundwater in Western Mediterranean countries. Environmental Earth Sciences. 79. <a href="https://doi.org/10.1007/s12665-020-09261-3" target="_blank">https://doi.org/10.1007/s12665-020-09261-3</a></li>
                        <li>Pulido-velazquez, David &amp; Romero, Javier &amp; Collados-Lara, Antonio-Juan &amp; Alcal&aacute;, Francisco &amp; Chac&oacute;n, Francisca &amp; Baena-Ruiz, Leticia. (2020). Using the Turnover Time Index to Identify Potential Strategic Groundwater Resources to Manage Droughts within Continental Spain. Water. 12. 3281. <a href="https://doi.org/10.3390/w12113281" target="_blank">https://doi.org/10.3390/w12113281</a></li>
                        <li>Baena-Ruiz, Leticia &amp; Pulido-velazquez, David, 2020. A Novel Approach to Harmonize Vulnerability Assessment in Carbonate and Detrital Aquifers at Basin Scale. Water 2020, 12, 2971; <a href="https://doi.org/10.3390/w12112971" target="_blank">https://doi.org/10.3390/w12112971</a></li>
                        <li>Collados-Lara, Antonio-Juan &amp; Fassnacht, S. &amp; Pulido-Velazquez, David &amp; Pfohl, Anna &amp; Mor&aacute;n-Tejeda, Enrique &amp; Venable, Niah &amp; Pardo-Iguzquiza, Eulogio &amp; Puntenney‐Desmond, Kira. (2020). Intra‐day variability of temperature and its near‐surface gradient with elevation over mountainous terrain: comparing MODIS Land Surface Temperature data with coarse and fine scale near‐surface measurements. International Journal of Climatology. <a href="https://doi.org/10.1002/joc.6778" target="_blank">https://doi.org/10.1002/joc.6778</a></li>
                        <li>Collados-Lara, Antonio-Juan &amp; Pulido-Velazquez, David &amp; Pardo-Iguzquiza, Eulogio &amp; Alonso-Gonz&aacute;lez, Esteban. (2020). Estimation of the spatiotemporal dynamic of snow water equivalent at mountain range scale under data scarcity. Science of The Total Environment. 741. <a href="https://doi.org/10.1016/j.scitotenv.2020.140485" target="_blank">https://doi.org/10.1016/j.scitotenv.2020.140485</a></li>
                        <li>Collados-Lara; AJ, Pulido-Velazquez; D. Pardo-Ig&uacute;zquiza, E., 2020. A statistical tool to generate potential future climate scenarios for hydrology applications. Scientific Programming&nbsp;2020(4) <a href="https://doi.org/10.1155/2020/8847571" target="_blank">https://doi.org/10.1155/2020/8847571</a></li>
                        <li>Jimeno-S&aacute;ez, P.; Pulido-Velazquez, D.; Collados-Lara AJ, Pardo-Ig&uacute;zquiza, E.; Senent-Aparicio, J. Baena-Ruiz, L.; 2020. A Preliminary Assessment of the &ldquo;Undercatching&rdquo; and the Precipitation Pattern in an Alpine Basin. Water 2020, 12, 1061; <a href="https://doi.org/10.3390/w12041061" target="_blank">https://doi.org/10.3390/w12041061</a></li>
                        <li>Baena-Ruiz, Leticia &amp; Pulido-velazquez, David &amp; Collados-Lara, Antonio-Juan &amp; Renau-Pru&ntilde;onosa, Arianna &amp; Morell, Ignacio &amp; Senent-Aparicio, Javier &amp; Llopis-Albert, Carlos. (2020). Summarizing the impacts of future potential global change scenarios on seawater intrusion at the aquifer scale. Environmental Earth Sciences. 79. <a href="https://doi.org/10.1007/s12665-020-8847-2" target="_blank">https://doi.org/10.1007/s12665-020-8847-2</a></li>
                        <li>Collados-Lara AJ, Pulido-Velazquez D, Mateos, RM, Ezquerro, P. 2020. Potential Impacts of Future Climate Change Scenarios on Ground Subsidence. Water, 12, 219. <a href="https://doi.org/10.3390/w12010219" target="_blank">https://doi.org/10.3390/w12010219</a></li>
                        <li>Senent-Aparicio, J.; Jimeno-S&aacute;ez, P.; Arias, M.; O&rsquo;Driscoll, L.; P&eacute;rez-S&aacute;nchez, J.; Le&oacute;n, L.; Alcal&aacute;, F-J; Pulido-Velazquez, D., 2019. Preparation of Technical Posters as a Tool to Improve Transversal Competences of Civil Engineering Studies. International Journal of Engineering Education Vol. 35, No. 6(A), pp. 1872&ndash;1880, 2019</li>
                        <li>Pardo-Iguzquiza, Eulogio &amp; Collados-Lara, Antonio-Juan &amp; Pulido-velazquez, David. (2019). Potential future impact of climate change on recharge in the Sierra de las Nieves (southern Spain) high-relief karst aquifer using regional climate models and statistical corrections. Environmental Earth Sciences. 78. <a href="https://doi.org/10.1007/s12665-019-8594-4" target="_blank">https://doi.org/10.1007/s12665-019-8594-4</a></li>
                        <li>Collados-Lara AJ, Pardo-Ig&uacute;zquiza E, Pulido-Velazquez D, 2020. Optimal design of snow stake networks to estimate snow depth in an alpine mountain. Hydrological Processes. <a href="https://doi.org/10.1002/hyp.13574" target="_blank">https://doi.org/10.1002/hyp.13574</a></li>
                        <li>P&eacute;rez-S&aacute;nchez, Julio &amp; Senent-Aparicio, Javier &amp; Segura-M&eacute;ndez, Francisco &amp; Pulido-velazquez, David &amp; Srinivasan, Raghavan. (2019). Evaluating Hydrological Models for Deriving Water Resources in Peninsular Spain. Sustainability. 11. <a href="https://doi.org/10.3390/su11102872" target="_blank">https://doi.org/10.3390/su11102872</a></li>
                        <li>Collados-Lara AJ, Pardo-Ig&uacute;zquiza E, Pulido-Velazquez D. 2019. A distributed cellular automata model to simulate potential future impacts of climate change on snow cover area. Advances in Water Resources, 124, 106-119. <a href="https://doi.org/10.1016/j.advwatres.2018.12.010" target="_blank">https://doi.org/10.1016/j.advwatres.2018.12.010</a></li>
                        <li>Alvarado, R.; Gökçen Uysal; Aj. Collados-Lara; AA Şorman; D.  Pulido-Velazquez and A. Sensoy., 2022. JH Comparison of sequential and variational assimilation methods to improve hydrological predictions in snow dominated mountainous catchments. Journal of Hydrology. <a href="https://doi.org/10.1016/j.jhydrol.2022.127981" target="_blank">https://doi.org/10.1016/j.jhydrol.2022.127981.</a> </li>
                        <li>Hidalgo-Hidalgo, J.-D.; Collados-Lara, A.-J.; Pulido-Velazquez, D.; Rueda-Valdivia, F.-J.; Pardo-Igúzquiza, E., 2022. Analysis of the Potential Impacts of Climate Change on climatic droughts, snow dynamics and the correlation between them. Water 2022, 14, 1081. <a href="https://doi.org/10.3390/W1471081" target="_blank">https://doi.org/10.3390/W1471081</a> </li>
                    </ul>
                </Typography>
            </Item>
            </Grid>
            <Grid item xs={12}>
            <Item>
                <Typography variant='p'style={{textAlign: 'left',padding:'10px 20px'}}>
                    <h2> 
                    Tesis doctorales
                    </h2>
                    <ul>
                    <li>Collados Lara, Antonio Juan. Assessing impacts of potential climate change scenarios in water resources systems depending on natural storage from snowpacks and/or groundwater. Granada: Universidad de Granada, 2019. <a href="https://digibug.ugr.es/handle/10481/56521" target="_blank">[https://digibug.ugr.es/handle/10481/56521]</a></li>
                    <li>Baena Ruiz, Leticia. Análisis y síntesis de impactos del cambio global en el estado y vulnerabilidad de masas de agua subterráneas. Granada: Universidad de Granada, 2021 <a href="https://digibug.ugr.es/handle/10481/69406" target="_blank"> [https://digibug.ugr.es/handle/10481/69406]</a></li>

                    </ul>
                </Typography>
            </Item>
            </Grid>
            <Grid item xs={12} >
          <Item > 
          <Typography variant='p'  style={{padding:'50px 20px'}}>
          <p>SIGLO-AN (RTI2018-101397-B-I00) financiado por el Programa Estatal de I+D+I orientada a los Retos de la Sociedad
          </p><p>
            Instituto Geológico y Minero de España (IGME-CSIC)
            Departamento de Aguas y Cambio Global
            Urbanización Alcázar del Genil nº 4. Edificio Zulema. Bajo. 18006 Granada
            </p><p>
            "info@seguimientodenieve.es" 
            "d.pulido@igme.es"</p>
            </Typography>
          </Item>
        </Grid>
        </Grid>
    </Box>
  )
}