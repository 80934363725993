import * as React from 'react';
import { Link  } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { requirePropFactory, Stack, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import TourOutLinedIcon from'@mui/icons-material/TourOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { margin } from '@mui/system';
import {TwitterTimelineEmbed} from 'react-twitter-embed';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: '0px',
  alignItems:'center'
}));



const Photos = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#000',
    ...theme.typography.body2,
    padding: '0px'/* theme.spacing(1) */,
    textAlign: 'top',
    color: theme.palette.text.secondary,
    borderRadius: '0px',
    /* backgroundImage:'url(../../assets/landpage/seguimientodenieve1.jpeg)' */
    backgroundColor:'fff',
    boxShadow:''
  }));

  const Parrafo = styled('p')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.h6,
    padding:'0px 50px'
}));  
export default function Home() {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item  xs={12} >
          <Photos style={{boxShadow: 'none'}}> <img src={require('../../assets/landpage/seguimientodenieve1.jpg') } style={{width:'100%',height:'400px',objectFit:'cover'/* ,objectPosition: '0px -250px' */}}/></Photos>          
        </Grid>
        <Grid item xs={12} >
          <Item>
              <h2>Iniciativa de participación Ciudadana</h2>
              <Parrafo>
              El objetivo de esta actividad es contribuir al conocimiento del cambio climático mediante el seguimiento de espesores de nieve en Sierra Nevada. La nieve es un excelente indicador del clima, y con vuestra ayuda podemos obtener información muy valiosa.</Parrafo>  
          </Item>
        </Grid>
        <Grid item xs={12} sm={4} >
          <Stack direction="column" spacing={2}>
          <Item>
            <Avatar sx={{width:"120px",height:"120px", bgcolor:blueGrey[500],margin:'auto'}}>
              <Link to='/pertigas' style={{color:"white"}}> 
                <TourOutLinedIcon style={{width:"100px",height:"100px" }}/>
                </Link>
            </Avatar>
            <Typography variant='h5' component="div" marginTop={"5px"}>Pértigas</Typography>
            <Typography variant='body2' color="GrayText.secundary" style={{padding:'0px 20px'}}>Gracias al Programa Nacional de Seguimiento de Nieve, contamos con una infraestructura que consiste en 21 pértigas distribuidas por Sierra Nevada. Podéis ver su ubicación exacta en el mapa, con las coordenadas de cada pértiga y descargárosla de wikilock.</Typography>
          </Item>
           
          <Item>
            <Avatar sx={{width:"120px",height:"120px", bgcolor:blueGrey[500],margin:'auto'}}>
                <EmojiEventsOutlinedIcon style={{width:"100px",height:"100px" }}/>
            </Avatar>
            <Typography variant='h5' component="div" >Ranking</Typography>
            <Typography variant='body2' color="GrayText.secundary" style={{padding:'0px 20px'}}>
                Se publicarán ranking, y habrá premios para los participantes más activos de la temporada. Además, vuestra participación será una contribución muy valiosa para el Seguimiento científico del Cambio Climático.
            </Typography>
          </Item>
          </Stack>
        </Grid>


        <Grid item xs={12} sm={4}>
          <img src={require('../../assets/landpage/movil01.png')} style={{margin:'auto'}}></img>
        </Grid>


        <Grid item xs={12} sm={4}>
        <Stack direction="column" spacing={2}>
          <Item>
            <Avatar sx={{width:"120px",height:"120px", bgcolor:blueGrey[500],margin:'auto'}}>
                <AddAPhotoIcon style={{width:"80px",height:"80px" }}/>
            </Avatar>
            <Typography variant='h5' component="div" marginTop={"5px"}>Reto</Typography>
            <Typography variant='body2' color="GrayText.secundary" style={{padding:'0px 20px'}}>
            Os proponemos un nuevo reto: Tomar el máximo número de fotos con nieve de las pértigas para extender en el tiempo los registros existentes, contribuyendo al seguimiento del cambio climático.

OJO, haced las fotos de las pértigas que se vean en su totalidad, así podremos calcular el espesor de nieve.</Typography>
          </Item>
           
          <Item>
            <Avatar sx={{width:"120px",height:"120px", bgcolor:blueGrey[500],margin:'auto'}}>
                <PhoneIphoneIcon style={{width:"100px",height:"100px" }}/>
            </Avatar>
            <Typography variant='h5' component="div" >Ayúdanos</Typography>
            <Typography variant='body2' color="GrayText.secundary" style={{padding:'0px 20px'}}>Mándanos la fotos por WhatsApp al 629938144.</Typography>
          </Item>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Item style={{backgroundImage:"url("+require('../../assets/landpage/IMG_2650_JPSanchez.jpeg')+")",height:"400px",backgroundSize:'cover'}}> 
            <Typography variant='h6' color="#fff" style={{padding:'50px 20px' , backgroundColor:"#00000066"}}>
            Este reto ha sido diseñado conjuntamente por personal del IGME y del Parque Nacional de Sierra Nevada. Contamos con el apoyo del Programa Nacional de Seguimiento de Nieve ERHIN (Ministerio de Transición Ecológica), al que pertenecen la mayoría de las pértigas seleccionadas, y la colaboración de la FAM, que participa también financiando los premios, así como la Diputación de Granada y el Ministerio de Ciencia e Innovación (Proyecto SIGLO-AN).
            </Typography>
          </Item>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Item > 
            <TwitterTimelineEmbed 
            sourceType='profile'
            screenName='Nieve_SIGLO_AN'
            options={{height: 496}}
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Item > 
          <div className="fb-page" 
            data-tabs="timeline"
            data-href="https://www.facebook.com/Seguimiento-de-Nieve-Siglo-An-110665023757515"
            data-width="380" 
            data-hide-cover="false"
            style={{height: 400}}>
        </div>
          </Item>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Item > 
          <a href='https://es.wikiloc.com/rutas-senderismo/ubicacion-pertigas-proyecto-seguimiento-de-nieve-siglo-an-61658512' target='_blank'>
          <img src={require('../../assets/landpage/wikiloc.jpeg') } style={{width:'100%',height:'100%',objectFit:'cover',marginTop:"15px"/* ,objectPosition: '0px -250px' */}}/>
          </a>
          </Item>
          <Item>
            <video loop autoPlay name="media" playsinline="true" style={{width:"100%",marginTop:"20px"}}>
               <source src="http://seguimientodenieve.es/webwp/wp-content/uploads/seguimientoNieve01_3.mp4" type="video/mp4" />     
            </video>
          {/*  */}
          </Item>
        </Grid>
        <Grid item xs={12} >
          <Item > 
          <Typography variant='p'  style={{padding:'50px 20px'}}>
          <p>SIGLO-AN (RTI2018-101397-B-I00) financiado por el Programa Estatal de I+D+I orientada a los Retos de la Sociedad
          </p><p>
            Instituto Geológico y Minero de España (IGME-CSIC)
            Departamento de Aguas y Cambio Global
            Urbanización Alcázar del Genil nº 4. Edificio Zulema. Bajo. 18006 Granada
            </p><p>
            "info@seguimientodenieve.es" 
            "d.pulido@igme.es"</p>
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
